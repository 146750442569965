/* eslint react/prop-types: 0 */
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ContactForm from '../components/contact-sanpham';
import AppAppBar from '../components/navbar/navbarvn';
import Footer from '../components/homepagevn/Footer';

const useStyles = makeStyles((theme) => ({
  image: {
    margin: 'auto',
    maxWidth: '100%',
    height: 'auto',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textWeb: {
    fontSize: '1.14rem',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  contactTittle: {
    marginTop: theme.spacing(8),
    fontWeight: 700,
    fontSize: '1.14rem',
  },
  buttonContact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  contactButton: {
    fontSize: '1.14rem',
    borderRadius: 300,
    height: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '12px',
    paddingBottom: '12px',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
  },
  cardGrid: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.only('md')]: {
      marginBottom: theme.spacing(10),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '12rem',
      marginBottom: theme.spacing(10),
    },
  },
  contentSubTitle: {
    fontSize: '1rem',
    color: '#615b5b',
    borderBottom: '1px solid #ddd',
    paddingBottom: '1rem',
    textTransform: 'uppercase',
  },
  contentContainer: {
    borderRight: '1px solid #ddd',
  },
  headerContainer: {
    marginBottom: '3rem',
  },
  header: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
    lineHeight: '1.5',
  },
  image1: {
    maxWidth: '100%',
    margin: 'auto',
  },
  actionButton: {
    marginTop: theme.spacing(5),
    testAlign: 'center',
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'san-serif',
    ].join(','),
    fontSize: '1.14rem',
    textDecoration: 'none',
    padding: '1rem 1.5rem 1rem 1.5rem',
    borderRadius: '10px',
    backgroundColor: '#f2ae1c',
    color: 'white',
  },
  quotationContainer: {
    width: '100%',
    marginTop: '2rem',
    height: 'auto',
    backgroundColor: '#f7f7f7',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default function SanPhamDacBiet({ data }) {
  const classes = useStyles();
  const post = data.mdx;

  return (
    <>
      <AppAppBar />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={post.frontmatter.description} />
        <meta name="keywords" content={post.frontmatter.keyword} />
        <meta property="og:image" content={post.frontmatter.feature_img} />
        <title>{post.frontmatter.title_meta}</title>
      </Helmet>
      <Container className={classes.cardGrid}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.headerContainer}>
            <Typography variant="h4" marked="center" align="center" component="h1" className={classes.header}>
              {post.frontmatter.title}
            </Typography>
            <Typography align="center" className={classes.contentSubTitle}>
              Home   -   Sản phẩm -
              {post.frontmatter.category}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8} className={classes.contentContainer}>
            <div className="blogbody">
              <MDXRenderer>{post.body}</MDXRenderer>
            </div>
            <div className={classes.quotationContainer}>
              <Grid container>
                <Grid item xs={1} lg={1} />
                <Grid item xs={10} lg={10}>
                  <br />
                  <Typography variant="h6" align="center" style={{ marginTop: '3rem', color: 'white' }} />
                  <br />
                  <Typography variant="h5" align="center" style={{ color: 'rgb(31,54,92)', textTransform: 'uppercase' }}>
                    <b> NHẬN BÁO GIÁ NGAY BÂY GIỜ </b>
                  </Typography>
                  <br />
                  <Typography gutterBottom variant="h6" component="h2" className={classes.textFont} align="center" style={{ marginBottom: '5rem', marginTop: '1rem' }}>
                    <a href="/lien-he" className={classes.actionButton}> Liên hệ ngay với chúng tôi </a>
                  </Typography>
                </Grid>
                <Grid item xs={1} lg={1} />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <br />
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        feature_img
        category
        slug
        title
        title_meta
        description
        keyword
      }
    }
  }
`;
