/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: '3.50rem',
      marginLeft: '25%',
      marginTop: '1rem',
    },
    fontFamily: [
      'Roboto', 'Helvetica', 'Arial', 'sans-serif',
    ].join(','),
    fontSize: '1rem',
    backgroundColor: '#f2ae1c',
  },
  content: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
  },
  content1: {
    fontSize: '1.14rem',
    lineHeight: '1.5',
    color: '#888',
  },
  formContainer: {
    borderBottom: '1px solid #ddd',
    paddingBottom: '3rem',
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <section style={{ backgroundColor: '#fff' }}>
      <Container className={classes.root}>
        <div>
          <Typography className={classes.content}>
            <b> Nhận báo giá </b>
          </Typography>
          <br />
          <Typography className={classes.content}>
            Liên lạc ngay với chúng tôi để nhận tư vấn chi tiết về sản phẩm
          </Typography>
          <div>
            <iframe src="https://chinhdai.getflycrm.com/api/forms/viewform/?key=ARyzXws2XBXs12al5FAdwdfT1Occ0KgqQOElVlJGscnqPUE51o" title="contact form" width="100%" height="400px" frameBorder="0" marginHeight="0" marginWidth="0">Loading ...</iframe>
          </div>
          <br />
          <Typography className={classes.content}>
            <b> Thông tin liên hệ </b>
          </Typography>
          <br />
          <Typography className={classes.content}> Mail </Typography>
          <Typography className={classes.content1}> trucnh@chinhdaisteel.com </Typography>
          <br />
          <Typography className={classes.content}> Hotline </Typography>
          <Typography className={classes.content1}>
            (+84) 913 236 789 / (+84) 976 208 688
          </Typography>
          <br />
          <Typography className={classes.content}> Fax </Typography>
          <Typography className={classes.content1}> (+84) 321 3989526 – 3989525 </Typography>
        </div>
      </Container>
    </section>
  );
}

export default Contact;
